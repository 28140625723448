import {timeFormat, numberFormat, ParseFloatFormat} from "@custom/index";
import {downloadContract, getContractData, getFlowUrl,getEqbelectContractUrlApi} from "@/api/contract";
import {MessageInfo} from "@custom/message";

// 身份证方法引入,“诺诺_保租房_整租"），1 usageNUONUO_BZF_SHARED_FLATS( code:11,name:"诺诺_保租房_合租"），
import {createFullImageUrl} from '@/components/Upload/index'
// 房源类型
export const houseApartmentType = ["一居室", "二居室", "三居室", "非成套"];

// 房源卡片，信息展示内容
export const houseOptions = [
    // 2023.07.25 张晓瑜新增
    {
        label: "楼栋",
        name: "buildUnitName"
    },
    {
        label: "承租人",
        name: "leasorName"
    },
    {
        label: "户型",
        name: "apartmentType"
    },
    {
        label: "面积",
        name: "area",
        unit: "㎡"
    },
];

// 房间的设备
export const houseDevice = [
    {
        label: "水表",
        name: "water",
    },
    {
        label: "电表",
        name: "elect",
    },
    {
        label: "气表",
        name: "gas",
    },
    {
        label: "智能锁",
        name: "intelLock",
    }
];

// 房间tabs
export const houseTabOptions = [
    /*{
        label: "房间预订",
        name: "book"
    },*/
    {
        label: "租房合同",
        name: "contract"
    },
    {
        label: "租房账单",
        name: "bill"
    },
    {
        label: "杂费账单",
        name: "other-bill"
    },
    {
        label: "房间详情",
        name: "detail"
    },
    {
        label: "房间资产",
        name: "asset"
    },
];

// 房间详情 - 租房合同 - 合同记录 - 表格
export function houseContractColumns(that) {
    return [
        {
            label: "承租人",
            render: (h, {row}) => {
                const {leasorName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$emit("closeDrawer");
                            that.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                        }
                    }
                }, leasorName);
            }
        },
        {
            label: "合同周期",
            width: 200,
            render: (h, {row}) => {
                const {startDate, endDate} = row;
                return timeFormat(new Date(startDate)) + " 至 " + timeFormat(new Date(endDate));
            }
        },
        {
            prop: "finished",
            label: "合同状态",
            render: (h, {row}) => {
                let {contractStatusCode: code, contractStatusCodeName: codeName} = row;
                let type = "";
                switch (code) {
                    case 500701:
                        type = "success";
                        break;
                    case 500702:
                        type = "info";
                        break;
                    case 500703:
                        type = "warning";
                        break;
                    case 500704:
                        type = "";
                        break;
                    case 500705:
                        type = "danger";
                        break;
                    case 500706:
                        type = "success";
                        break;
                    case 500709:
                        codeName = "待完善";
                        break;
                }
                return h("el-tag", {props: {type: type, size: "small"}}, codeName);
            }
        },
        {
            prop: "isExchange",
            label: "签约类型",
            render: (h, {row}) => {
                let {isExchange} = row;
                isExchange = isExchange || "新签";
                return isExchange;
            }
        },
        {
            prop: "rent",
            label: "租金",
            width: 100,
            render: (h, {row}) => {
                return numberFormat(row.rent / 100);
            }
        },
        {
            prop: "depositAmount",
            label: "押金",
            width: 100,
            render: (h, {row}) => {
                return numberFormat(row.depositAmount / 100);
            }
        },
        {
            prop: "serviceAmount",
            label: "服务费",
            width: 100,
            render: (h, {row}) => {
                return numberFormat(row.serviceAmount / 100);
            }
        }
    ];
}

// 房间详情 - 租房合同 - 合同记录 - 保租房表格
export function houseContractBzfColumns(that) {
    return [
        {
            label: "承租人",
            render: (h, {row}) => {
                const {leasorName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$emit("closeDrawer");
                            that.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                        }
                    }
                }, leasorName);
            }
        },
        {
            label: "房间名",
            prop: "apartmentName",
            width: 200,
            showOverflowTooltip: true,
        },
        {
            label: "合同周期",
            width: 200,
            render: (h, {row}) => {
                const {startDate, endDate} = row;
                return timeFormat(new Date(startDate)) + " 至 " + timeFormat(new Date(endDate));
            }
        },
        {
            prop: "finished",
            label: "合同状态",
            render: (h, {row}) => {
                let {contractStatusCode: code, contractStatusCodeName: codeName} = row;
                let type = "";
                switch (code) {
                    case 500701:
                        type = "success";
                        break;
                    case 500702:
                        type = "info";
                        break;
                    case 500703:
                        type = "warning";
                        break;
                    case 500704:
                        type = "";
                        break;
                    case 500705:
                        type = "danger";
                        break;
                    case 500706:
                        type = "success";
                        break;
                    case 500709:
                        codeName = "待完善";
                        break;
                }
                return h("el-tag", {props: {type: type, size: "small"}}, codeName);
            }
        },
        {
            prop: "isExchange",
            label: "签约类型",
            render: (h, {row}) => {
                let {isExchange} = row;
                isExchange = isExchange || "新签";
                return isExchange;
            }
        },
        {
            prop: "rent",
            label: "租金",
            width: 100,
            render: (h, {row}) => {
                return numberFormat(row.rent / 100);
            }
        },
        {
            prop: "depositAmount",
            label: "押金",
            width: 100,
            render: (h, {row}) => {
                return numberFormat(row.depositAmount / 100);
            }
        },
        {
            prop: "serviceAmount",
            label: "服务费",
            width: 100,
            render: (h, {row}) => {
                return numberFormat(row.serviceAmount / 100);
            }
        }
    ];
}

// 房间详情 - 租房合同 - 承租人信息 - 详情
export function houseLesseeSchemas(_this) {
    return [
        {
            label: "租客姓名",
            name: "leasorName"
        },
        {
            label: "手机号码",
            name: "leasorPhone"
        },
        /*{
            label: "证件类型",
            name: "idType"
        },*/
        {
            label: "证件号码",
            name: "idCard"
        },
        {
            label: "紧急联系人",
            name: "contactName",
        },
        {
            label: "紧急联系电话",
            name: "contactPhone",
        },
        {
            label: "租客同住人",
            name: "contractPhotos",
            render: (h, {value, data}) => {
                const look = h("el-link", {
                    props: {type: "primary", underline: false},
                    style: {marginRight: "10px"},
                    on: {
                        click() {
                            _this.tongzhuren(data, "look");
                        }
                    }
                }, "查看");
                const save = h("el-link", {
                    props: {type: "primary", underline: false},
                    style: {marginRight: "10px"},
                    on: {
                        click() {
                            _this.tongzhuren(data, "save");
                        }
                    }
                }, "添加");
                const wei = h("span", {style: {marginRight: "10px"}}, data.familyCount + "位");
                let arr = [];

                if (data.familyCount !== 0) arr.push(look, save, wei);
                else arr.push(save, wei);

                return arr;
            }
        },
    ];
}

export function houseLesseeSchemas2(_this) {
    return [
        {
            label: "租客姓名",
            name: "leasorName"
        },
        {
            label: "手机号码",
            name: "leasorPhone"
        },
        /*{
            label: "证件类型",
            name: "idType"
        },*/
        {
            label: "证件号码",
            name: "idCard"
        },
        {
            label: "法人姓名",
            name: "juridicalPersonName"
        },

        {
            label: "紧急联系人",
            name: "contactName",
        },
        {
            label: "法人身份证号",
            name: "juridicalPersonIdcard"
        },
        {
            label: "紧急联系电话",
            name: "contactPhone",
        },
        {
            label: "租客同住人",
            name: "contractPhotos",
            render: (h, {value, data}) => {
                const look = h("el-link", {
                    props: {type: "primary", underline: false},
                    style: {marginRight: "10px"},
                    on: {
                        click() {
                            _this.tongzhuren(data, "look");
                        }
                    }
                }, "查看");
                const save = h("el-link", {
                    props: {type: "primary", underline: false},
                    style: {marginRight: "10px"},
                    on: {
                        click() {
                            _this.tongzhuren(data, "save");
                        }
                    }
                }, "添加");
                const wei = h("span", {style: {marginRight: "10px"}}, data.familyCount + "位");
                let arr = [];

                if (data.familyCount !== 0) arr.push(look, save, wei);
                else arr.push(save, wei);

                return arr;
            }
        },
    ];
}

// 房间详情 - 租房合同 - 合同信息 - 详情
export function houseContractSchemas(_this) {
    return [
        {
            label: "合同编号",
            name: "contractNum"
        },
        {
            label: "合同周期",
            name: "startDate",
            render: (h, {data}) => {
                const {startDate, endDate} = data;
                return timeFormat(new Date(startDate)) + " 至 " + timeFormat(new Date(endDate));
            }
        },
        {
            label: "签约时间",
            name: "signedDate",
            render: (h, {value}) => {
                return timeFormat(new Date(value));
            }
        },
        {
            label: "租赁时间",
            name: "rentMonth",
            render: (h, {value}) => {
                return value || '--' + "月";
            }
        },
        {
            label: "租房金额",
            name: "rent",
            render: (h, {value}) => {
                return numberFormat(value / 100);
            }
        },
        {
            label: "押金",
            name: "depositAmount",
            render: (h, {value}) => {
                return numberFormat(value / 100);
            }
        },
        {
            label: "服务费",
            name: "serviceAmount",
            render: (h, {value}) => {
                return numberFormat(value / 100);
            }
        },
        /*{
            label: "收款周期",
            name: "entryPerson",
        },*/
        {
            // 陈守亮-2024-3-27-修改详情和合同的合同状态一致
            label: "合同状态",
            name: "contractStatusCode",
            render: (h, {value}) => {
                console.log(h,value)
                let codeName = ''
                let type = "";
                switch (value) {
                    case 500701:
                        type = "success";
                        codeName = "正在进行";
                        break;
                    case 500702:
                        type = "info";
                        codeName = "已到期";
                        break;
                    case 500703:
                        type = "warning";
                        codeName = "已结束";
                        break;
                    case 500704:
                        type = "";
                        codeName = "待生效";
                        break;
                    case 500705:
                        type = "danger";
                        codeName = "已作废";
                        break;
                    case 500706:
                        type = "success";
                        codeName = "已付款";
                        break;
                    case 500707:
                        type = "success";
                        codeName = "待审核";
                        break;
                    case 500708:
                        type = "success";
                        codeName = "审核成功";
                        break;
                    case 500709:
                        codeName = "待完善";
                        break;
                }
                return h("el-tag", {props: {type: type, size: "small"}}, codeName);
            }
        },
        {
            label: "电子合同",
            name: "url",
            render: (h, {value, data}) => {
                console.log('data',data);
                let arr = [];
                let {contractStatusCode: code, idType, isCreateElectContract,url,electContractTypeCode,electContractPlatform,uuid} = data;
                //electContractPlatform 0无 1E签宝 2诺诺 3本系统企业pdf
                // 之前是区分-1到20然后改成字段-，0123
                if (idType === '法人') {
                    return h('span', '企业用户无电子合同');
                }
                if (!isCreateElectContract) {
                    return h('span', '当前合同为线下签约，请查看合同附件');
                }
                console.log(code,'code')
                if (code !== 500705) {
                    arr.push(h("el-link", {
                        props: {type: "primary", underline: false},
                        style: {marginRight: '10px'},
                        on: {
                            click() {
                                if (electContractPlatform === 1 ) {

                                    _this.getEQBFlowUrl(uuid);
                                    // let tempwindow = window.open("", '_blank');
                                    // tempwindow.location = value;
                                }else if(electContractPlatform === 0){
                                    return h('span', '暂无无电子合同');
                                } else {
                                    // console.log(_this.isbzf,'217')
                                    if (_this.isbzf) {
                                        let {uuid} = data;
                                        const loadingOptions = _this.loadingOptions;
                                        let loading = _this.$loading({...loadingOptions});
                                        data.contractUuid = data.uuid;
                                        console.log(data)
                                        getContractData(data).then(({info = {}}) => {
                                        // getFlowUrl({uuid:data.contractUuid}).then(({info = {}}) => {
                                            console.log(info)
                                            let {contractStatus = null} = info;
                                            if (contractStatus === null || contractStatus === 1) {
                                                localStorage.setItem("paramData", JSON.stringify(data));
                                                // alert(_this.$newWindowstype)
                                                if(_this.$newWindowstype === 0){
                                                    const newWindows = _this.$router.resolve({path: "/test-canvas"});
                                                    window.open(newWindows.href, '_blank');
                                                }else {
                                                    const newWindows = _this.$router.resolve({path: "/test-canvas-son"});
                                                    window.open(newWindows.href, '_blank');
                                                }
                                            } else {
                                                loading = _this.$loading({...loadingOptions});
                                                downloadContract(uuid).then(res => {
                                                    let {msg} = res;
                                                    if (msg) {
                                                        let blob = _this.base64ToBlob(msg);
                                                        let pdfUrl = window.URL.createObjectURL(blob);
                                                        let tempwindow = window.open("", '_blank');
                                                        tempwindow.location = pdfUrl;
                                                    }
                                                }).finally(() => loading.close());
                                            }
                                        }).finally(() => loading.close());
                                    } else {
                                        MessageInfo("暂无电子合同,无法查看");
                                    }
                                }
                            }
                        }
                    }, "查看"));
                }
                if (code === 500701 || code === 500704) {
                    arr.push(h("el-link", {
                        props: {type: "primary", underline: false},
                        on: {
                            click() {
                                const {uuid} = data;
                                _this.$confirm("是否重新签署当前电子合同?", "注意", {type: "warning"}).then(() => {
                                    const loadingOptions = _this.loadingOptions;
                                    const loading = _this.$loading({...loadingOptions});
                                    getEqbelectContractUrlApi({uuid}).then(({info}) => {
                                        if(info.electContractTypeEnumCode === 10 || info.electContractTypeEnumCode === 11){
                                            _this.bzfInvalid(uuid,info.electContractTypeEnumCode);
                                        }else if(info.electContractTypeEnumCode !== -1 || info.electContractTypeEnumCode !== 20) {
                                            console.log(info.urlDto)
                                            _this.contractSignTow(uuid,info.urlDto.url);
                                        }
                                    })
                                }).catch(() => {
                                });

                                // if (_this.isbzf)_this.bzfInvalid(uuid);

                                // else _this.contractSign(uuid);
                            }
                        }
                    }, "重新签署"));
                }
                return arr;
            }
        },
        {
            label: "合同附件",
            name: "url",
            render: (h, {data}) => {
                let arr = [];
                if (data.contractPhotos !== null && data.contractPhotos !== '' || data.pdfUrl !== null && data.pdfUrl !== '') {
                    arr = [
                        h("el-link", {
                            props: {type: "primary", underline: false},
                            style: {marginRight: "10px"},
                            on: {
                                click() {
                                    _this.lookFile(data);
                                }
                            }
                        }, "查看附件"),
                        h("el-link", {
                            props: {type: "primary", underline: false},
                            on: {
                                click() {
                                    _this.saveFile(data);
                                }
                            }
                        }, "添加附件")
                    ];
                } else {
                    arr = [h("el-link", {
                        props: {type: "primary", underline: false},
                        on: {
                            click() {
                                _this.saveFile(data);
                            }
                        }
                    }, "添加附件")];
                }
                return arr;
            }
        },
        {
            label:"证件照片",
            name: "uuid",
            render: (h, { data }) => {
                console.log('data',data);
              let arr = [];

              // 添加正面身份证链接和点击事件
              if (_this.contractInfo.frontIdcard) {
                const a = h(
                  "el-link",
                  {
                    props: { type: "primary", underline: false },
                    style: { marginRight: "10px" },
                    on: {
                      click() {
                          console.log(_this.contractInfo.frontIdcard)
                        const imgs = createFullImageUrl(_this.contractInfo.frontIdcard);
                        _this.immgshow(imgs);
                      },
                    },
                  },
                  "身份证正面"
                );
                arr.push(a);
              }

              // 添加反面身份证链接和点击事件
              if (_this.contractInfo.reverseIdcard) {
                const b = h(
                  "el-link",
                  {
                    props: { type: "primary", underline: false },
                    style: { marginRight: "10px" },
                    on: {
                      click() {
                        const imgs = createFullImageUrl(_this.contractInfo.reverseIdcard);
                        _this.immgshow(imgs);
                      },
                    },
                  },
                  "身份证反面"
                );
                arr.push(b);
              }

              // 添加营业执照链接和点击事件（如果有营业执照数据）
              if (_this.contractInfo && _this.contractInfo.businessLicense) {
                const c = h(
                  "el-link",
                  {
                    props: { type: "primary", underline: false },
                    style: { marginRight: "10px" },
                    on: {
                      click() {
                        const imgs = createFullImageUrl(_this.contractInfo.businessLicense);
                        _this.immgshow(imgs);
                      },
                    },
                  },
                  "营业执照"
                );
                arr.push(c);
              }

              return arr;
            },
          },
        {
            label: "备注",
            name: "comment",
            render: (h, {data}) => {
                return h("el-link", {
                    props: {
                        type: "primary",
                        underline: false
                    },
                    on: {
                        click() {
                            _this.editComment(data);
                        }
                    }
                }, "编辑");
            }
        },
        {
            label: "经办人",
            name: "manager",
            render: (h, {value}) => {
                return _this.contractInfo.manager
            }
        },
        {
            label: "管理人",
            name: "keeper",
            render: (h, {value}) => {
                return _this.contractInfo.keeper
            }
        },
    ]
}

// 房间详情 - 租房账单 - 表格
export function houseBillColumns(that) {
    return [
        {
            label: "账单周期",
            width: 200,
            render: (h, {row}) => {
                const {startDate, endDate} = row;
                return timeFormat(new Date(startDate)) + " 至 " + timeFormat(new Date(endDate));
            }
        },
        {
            label: "房源地址",
            width: 200,
            showOverflowTooltip: true,
            render: (h, {row}) => {
                const {apartment: {name}} = row;
                return h("span", name);
            }
        },
        {
            label: "账单状态",
            width: 100,
            render: (h, {row}) => {
                let {stateCodeName, stateCode, statusCode} = row;
                stateCodeName = stateCodeName ? stateCodeName : (stateCode || statusCode) === 600601 ? "待处理" : (stateCode || statusCode) === 600602 ? "已缴费" : (stateCode || statusCode) === 600603 ? "已逾期" : (stateCode || statusCode) === 600604 ? "已作废" : "";
                return h(
                    "el-tag",
                    {
                        props: {
                            type: (stateCode || statusCode) === 600602 ? "success" : (stateCode || statusCode) === 600601 ? "warning" : (stateCode || statusCode) === 600604 ? 'info' : '',
                            size: "small"
                        }
                    },
                    stateCodeName
                );
            }
        },
        {
            label: "账单类型",
            width: 80,
            render: (h, {row}) => {
                const {typeName, stateCode, type, startDate, endDate, paymentChannel, paidDate, billNum} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$emit("closeDrawer");
                            that.$router.push({
                                name: "payment-record",
                                params: {
                                    data: {
                                        stateCode, type, startDate, endDate, paymentChannel, paidDate, keyWordCode: 1,
                                        keyWord: billNum
                                    }
                                }
                            });
                        }
                    }
                }, typeName);
            }
        },
        {
            label: "账单金额",
            prop: 'amount',
            width: 80,
            render: (h, {row}) => {
                const {amount, stateCode} = row;
                return h("span", {
                    style: {
                        textDecoration: stateCode === 600604 ? 'line-through' : ''
                    }
                }, numberFormat(amount / 100));
            }
        },
        {
            label: "支付时间",
            width: 150,
            render: (h, {row}) => {
                let {paidDate} = row;
                paidDate = paidDate ? timeFormat(new Date(paidDate), "yyyy-MM-dd HH:mm:ss") : "";
                return h("span", paidDate);
            }
        },
        {
            label: "应收款日期",
            width: 95,
            render: (h, {row}) => {
                const {startDate} = row;
                return timeFormat(new Date(startDate));
            }
        },
        /*{
            prop: "name",
            label: "账单名称",
            showOverflowTooltip: true
        },*/
        /*{
            prop: "comment",
            label: "账单备注",
            showOverflowTooltip: true
        },*/
    ];
}

// 房间详情 - 杂费账单 - 表格
export function houseOtherBillColumns(that) {
    return [
        {
            label: "账单周期",
            width: 200,
            render: (h, {row}) => {
                const {startDate, endDate} = row;
                return timeFormat(new Date(startDate)) + " 至 " + timeFormat(new Date(endDate));
            }
        },
        {
            label: "房源地址",
            width: 200,
            showOverflowTooltip: true,
            render: (h, {row}) => {
                const {apartment: {name}} = row;
                return h("span", name);
            }
        },
        {
            label: "账单状态",
            width: 100,
            render: (h, {row}) => {
                let {stateCodeName, stateCode, statusCode} = row;
                stateCodeName = stateCodeName ? stateCodeName : (stateCode || statusCode) === 600601 ? "待处理" : (stateCode || statusCode) === 600602 ? "已缴费" : (stateCode || statusCode) === 600603 ? "已逾期" : (stateCode || statusCode) === 600604 ? "已作废" : "";
                return h(
                    "el-tag",
                    {
                        props: {
                            type: (stateCode || statusCode) === 600602 ? "success" : (stateCode || statusCode) === 600601 ? "warning" : (stateCode || statusCode) === 600604 ? 'info' : '',
                            size: "small"
                        }
                    },
                    stateCodeName
                );
            }
        },
        {
            label: "账单类型",
            width: 80,
            render: (h, {row}) => {
                const {typeName, stateCode, type, startDate, endDate, paymentChannel, paidDate, billNum} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$emit("closeDrawer");
                            that.$router.push({
                                name: "payment-record",
                                params: {
                                    data: {
                                        stateCode, type, startDate, endDate, paymentChannel, paidDate, keyWordCode: 1,
                                        keyWord: billNum
                                    }
                                }
                            });
                        }
                    }
                }, typeName);
            }
        },
        {
            label: "账单金额",
            prop: 'amount',
            width: 80,
            render: (h, {row}) => {
                const {amount, stateCode} = row;
                return h("span", {
                    style: {
                        textDecoration: stateCode === 600604 ? 'line-through' : ''
                    }
                }, numberFormat(amount / 100));
            }
        },
        {
            label: "支付时间",
            width: 150,
            render: (h, {row}) => {
                let {paidDate} = row;
                paidDate = paidDate ? timeFormat(new Date(paidDate), "yyyy-MM-dd HH:mm:ss") : "";
                return h("span", paidDate);
            }
        },
        {
            label: "应收款日期",
            width: 95,
            render: (h, {row}) => {
                const {startDate} = row;
                return h("span", timeFormat(new Date(startDate)));
            }
        },
        /*{
            prop: "name",
            label: "名称",
            showOverflowTooltip: true
        },
        {
            prop: "comment",
            label: "账单备注",
            showOverflowTooltip: true
        },*/
    ];
}

// 企业--房间详情 --合同信息
export function houseContractSchemas2(_this) {
    return [
        {
            label: "合同编号",
            name: "contractNum"
        },
        {
            label: "合同周期",
            name: "startDate",
            render: (h, {data}) => {
                const {startDate, endDate} = data;
                return timeFormat(new Date(startDate)) + " 至 " + timeFormat(new Date(endDate));
            }
        },
        {
            label: "签约时间",
            name: "signedDate",
            render: (h, {value}) => {
                return timeFormat(new Date(value));
            }
        },
        {
            label: "租赁时间",
            name: "rentMonth",
            render: (h, {value}) => {
                return value || '--' + "月";
            }
        },
        {
            label: "租房金额",
            name: "rent",
            render: (h, {value}) => {
                return numberFormat(value / 100);
            }
        },
        {
            label: "押金",
            name: "depositAmount",
            render: (h, {value}) => {
                return numberFormat(value / 100);
            }
        },
        {
            label: "服务费",
            name: "serviceAmount",
            render: (h, {value}) => {
                return numberFormat(value / 100);
            }
        },
        /*{
            label: "收款周期",
            name: "entryPerson",
        },*/
        {
            // 陈守亮-2024-3-27-修改详情和合同的合同状态一致
            label: "合同状态",
            name: "contractStatusCode",
            render: (h, {value}) => {
                console.log(h,value)
                let codeName = ''
                let type = "";
                switch (value) {
                    case 500701:
                        type = "success";
                        codeName = "正在进行";
                        break;
                    case 500702:
                        type = "info";
                        codeName = "已到期";
                        break;
                    case 500703:
                        type = "warning";
                        codeName = "已结束";
                        break;
                    case 500704:
                        type = "";
                        codeName = "待生效";
                        break;
                    case 500705:
                        type = "danger";
                        codeName = "已作废";
                        break;
                    case 500706:
                        type = "success";
                        codeName = "已付款";
                        break;
                    case 500707:
                        type = "success";
                        codeName = "待审核";
                        break;
                    case 500708:
                        type = "success";
                        codeName = "审核成功";
                        break;
                    case 500709:
                        codeName = "待完善";
                        break;
                }
                return h("el-tag", {props: {type: type, size: "small"}}, codeName);
            }
        },
        {
            label: "电子合同",
            name: "url",
            render: (h, {value, data}) => {
                console.log('data',data);
                let arr = [];
                let {contractStatusCode: code, idType, isCreateElectContract} = data;
                if (idType === '法人') {
                    return h('span', '企业用户无电子合同');
                }
                if (!isCreateElectContract) {
                    return h('span', '当前合同为线下签约，请查看合同附件');
                }
                console.log(code,'code')
                if (code !== 500705) {
                    arr.push(h("el-link", {
                        props: {type: "primary", underline: false},
                        style: {marginRight: '10px'},
                        on: {
                            click() {
                                if (value !== null) {
                                    let tempwindow = window.open("", '_blank');
                                    tempwindow.location = value;
                                } else {
                                    console.log(_this.isbzf,'217')
                                    if (_this.isbzf) {
                                        let {uuid} = data;
                                        const loadingOptions = _this.loadingOptions;
                                        let loading = _this.$loading({...loadingOptions});
                                        data.contractUuid = data.uuid;
                                        console.log(data)
                                        getContractData(data).then(({info = {}}) => {
                                            // getFlowUrl({uuid:data.contractUuid}).then(({info = {}}) => {
                                            console.log(info)
                                            let {contractStatus = null} = info;
                                            if (contractStatus === null || contractStatus === 1) {
                                                localStorage.setItem("paramData", JSON.stringify(data));
                                                // alert(_this.$newWindowstype)
                                                if(_this.$newWindowstype === 0){
                                                    const newWindows = _this.$router.resolve({path: "/test-canvas"});
                                                    window.open(newWindows.href, '_blank');
                                                }else {
                                                    const newWindows = _this.$router.resolve({path: "/test-canvas-son"});
                                                    window.open(newWindows.href, '_blank');
                                                }

                                            } else {
                                                loading = _this.$loading({...loadingOptions});
                                                downloadContract(uuid).then(res => {
                                                    let {msg} = res;
                                                    if (msg) {
                                                        let blob = _this.base64ToBlob(msg);
                                                        let pdfUrl = window.URL.createObjectURL(blob);
                                                        let tempwindow = window.open("", '_blank');
                                                        tempwindow.location = pdfUrl;
                                                    }
                                                }).finally(() => loading.close());
                                            }
                                        }).finally(() => loading.close());
                                    } else {
                                        MessageInfo("暂无电子合同,无法查看");
                                    }
                                }
                            }
                        }
                    }, "查看"));
                }
                if (code === 500701 || code === 500704) {
                    arr.push(h("el-link", {
                        props: {type: "primary", underline: false},
                        on: {
                            click() {
                                const {uuid} = data;
                                if (_this.isbzf) _this.bzfInvalid(uuid);
                                else _this.contractSign(uuid);
                            }
                        }
                    }, "重新签署"));
                }
                return arr;
            }
        },
        {
            label: "合同附件",
            name: "url",
            render: (h, {data}) => {
                let arr = [];
                if (data.contractPhotos !== null && data.contractPhotos !== '' || data.pdfUrl !== null && data.pdfUrl !== '') {
                    arr = [
                        h("el-link", {
                            props: {type: "primary", underline: false},
                            style: {marginRight: "10px"},
                            on: {
                                click() {
                                    _this.lookFile(data);
                                }
                            }
                        }, "查看附件"),
                        h("el-link", {
                            props: {type: "primary", underline: false},
                            on: {
                                click() {
                                    _this.saveFile(data);
                                }
                            }
                        }, "添加附件")
                    ];
                } else {
                    arr = [h("el-link", {
                        props: {type: "primary", underline: false},
                        on: {
                            click() {
                                _this.saveFile(data);
                            }
                        }
                    }, "添加附件")];
                }
                return arr;
            }
        },
        {
            label:"营业执照",
            name: "uuid",
            render: (h, { data }) => {
                console.log('data',data);
                let arr = [];
                // 添加营业执照链接和点击事件（如果有营业执照数据）
                if (_this.contractInfo && _this.contractInfo.businessLicense) {
                    const c = h(
                        "el-link",
                        {
                            props: { type: "primary", underline: false },
                            style: { marginRight: "10px" },
                            on: {
                                click() {
                                    const imgs = createFullImageUrl(_this.contractInfo.businessLicense);
                                    _this.immgshow(imgs);
                                },
                            },
                        },
                        "营业执照"
                    );
                    arr.push(c);
                }

                return arr;
            },
        },
        {
            label:"授权书",
            name: "uuid",
            render: (h, { data }) => {
                console.log('data',data);
                let arr = [];
                // 添加营业执照链接和点击事件（如果有营业执照数据）
                if (_this.contractInfo && _this.contractInfo.powerOfAttorneyImg) {
                    const c = h(
                        "el-link",
                        {
                            props: { type: "primary", underline: false },
                            style: { marginRight: "10px" },
                            on: {
                                click() {
                                    let imgList = _this.contractInfo.powerOfAttorneyImg.split(",");
                                    const imgs = imgList.map(item => {
                                        return createFullImageUrl(item)
                                    });
                                    _this.immgshowpowerOfAttorney(imgs);
                                },
                            },
                        },
                        "授权书"
                    );

                    arr.push(c);
                    // arr.push(b);
                    // arr.push(a);
                }


                return arr;
            },
        },
        {
            label:"法人身份证",
            name: "uuid",
            render: (h, { data }) => {
                console.log('data',data);
                let arr = [];

                // 添加正面身份证链接和点击事件
                if (_this.contractInfo.juridicalPersonFrontIdcard) {
                    const a = h(
                        "el-link",
                        {
                            props: { type: "primary", underline: false },
                            style: { marginRight: "10px" },
                            on: {
                                click() {
                                    console.log(_this.contractInfo.juridicalPersonFrontIdcard)
                                    const imgs = createFullImageUrl(_this.contractInfo.juridicalPersonFrontIdcard);
                                    _this.immgshow(imgs);
                                },
                            },
                        },
                        "身份证正面"
                    );
                    arr.push(a);
                }

                // 添加反面身份证链接和点击事件
                if (_this.contractInfo.juridicalPersonReverseIdcard) {
                    const b = h(
                        "el-link",
                        {
                            props: { type: "primary", underline: false },
                            style: { marginRight: "10px" },
                            on: {
                                click() {
                                    const imgs = createFullImageUrl(_this.contractInfo.juridicalPersonReverseIdcard);
                                    _this.immgshow(imgs);
                                },
                            },
                        },
                        "身份证反面"
                    );
                    arr.push(b);
                }


                return arr;
            },
        },
        {
            label:"紧急联系人身份证",
            name: "uuid",

            render: (h, { data }) => {
                console.log('data',data);
                let arr = [];

                // 添加正面身份证链接和点击事件
                if (_this.contractInfo.contactFrontIdcard) {
                    const a = h(
                        "el-link",
                        {
                            props: { type: "primary", underline: false },
                            style: { marginRight: "10px" },
                            on: {
                                click() {
                                    console.log(_this.contractInfo.contactFrontIdcard)
                                    const imgs = createFullImageUrl(_this.contractInfo.contactFrontIdcard);
                                    _this.immgshow(imgs);
                                },
                            },
                        },
                        "身份证正面"
                    );
                    arr.push(a);
                }

                // 添加反面身份证链接和点击事件
                if (_this.contractInfo.contactReverseIdcard) {
                    const b = h(
                        "el-link",
                        {
                            props: { type: "primary", underline: false },
                            style: { marginRight: "10px" },
                            on: {
                                click() {
                                    const imgs = createFullImageUrl(_this.contractInfo.contactReverseIdcard);
                                    _this.immgshow(imgs);
                                },
                            },
                        },
                        "身份证反面"
                    );
                    arr.push(b);
                }


                return arr;
            },
        },
        {
            label: "经办人",
            name: "manager",
            render: (h, {value}) => {
                return _this.contractInfo.manager
            }
        },
        {
            label: "管理人",
            name: "keeper",
            render: (h, {value}) => {
                return _this.contractInfo.keeper
            }
        },
        {
            label: "备注",
            name: "comment",
            render: (h, {data}) => {
                return h("el-link", {
                    props: {
                        type: "primary",
                        underline: false
                    },
                    on: {
                        click() {
                            _this.editComment(data);
                        }
                    }
                }, "编辑");
            }
        },

    ]
}
// 房间详情 - 租房账单 - 表格
export const houseDetailSchemas = [
    // 富航详情展示数据
    {
        label: "房间号",
        name: "roomNum"
    },
    {
        label: "原价",
        name: "displayAmount",
        render: (h, {value}) => {
            return numberFormat(value / 100);
        }
    },
    {
        label: "半年租金",
        name: "halfAmount",
        render: (h, {value}) => {
            return numberFormat(value / 100);
        }
    },
    {
        label: "优惠价",
        name: "amount",
        render: (h, {value}) => {
            return numberFormat(value / 100);
        }
    },
    {
        label: "服务费",
        name: "tipAmount",
        render: (h, {value}) => {
            return numberFormat(value / 100);
        }
    },
    {
        label: "房间户型",
        name: "houseType"
    },
    {
        label: "地址",
        name: "address"
    },
    {
        label: "建筑面积",
        name: "area",
        render: (h, {value}) => {
            return `${value}㎡`;
        }
    },
    {
        label: "室内面积",
        name: "insideArea",
        render: (h, {value}) => {
            return `${value}㎡`;
        }
    },
    /*{
        label: "装修时间",
        name: "decorationTime",
        render: (h, {value}) => {
            return value ? `${timeFormat(value, "yyyy-MM-dd HH:mm")}` : "";
        }
    },
    {
        label: "是否保洁",
        name: "clean"
    },*/
    {
        label: "房间创建人",
        name: "entryPerson"
    },
    {
        label: "备注",
        name: "comment"
    },
];
// 2023-10-23-11-56-陈守亮-保租房-富航-详情数据分离
export const houseDetailSchemasFalse = [
    // 保租房数据展示
    {
        label: "房间号",
        name: "roomNum"
    },
    {
        label: "半年租金",
        name: "halfAmount",
        render: (h, {value}) => {
            return `${numberFormat(value / 100)}元/月`;
        }
    },
    {
        label: "单价",
        name: "monthPriceAmount",
        render: (h, {value}) => {
            return `${numberFormat(value / 100)}元`;
        }
    },
    {
        label: "年租金",
        name: "yearAmount",
        render: (h, {value}) => {
            return `${numberFormat(value / 100)}元`;
        }
    },
    {
        label: "房间户型",
        name: "houseType"
    },
    {
        label: "地址",
        name: "address"
    },
    {
        label: "建筑面积",
        name: "area",
        render: (h, {value}) => {
            return `${value}㎡`;
        }
    },
    {
        label: "室内面积",
        name: "insideArea",
        render: (h, {value}) => {
            return `${value}㎡`;
        }
    },
    /*{
        label: "装修时间",
        name: "decorationTime",
        render: (h, {value}) => {
            return value ? `${timeFormat(value, "yyyy-MM-dd HH:mm")}` : "";
        }
    },
    {
        label: "是否保洁",
        name: "clean"
    },*/
    {
        label: "房间创建人",
        name: "entryPerson"
    },
    {
        label: "备注",
        name: "comment"
    },
];
// 房间详情 - 出租情况 - 详情
export const lesseeDetailSchemas = [
    {
        label: "租客",
        name: "leasorName"
    },
    /*{
        label: "出租状态",
        name: "houseStatus"
    },*/
    {
        label: "入住周期",
        name: "leaseCycle"
    },
];

// 2022/03/21 王江毅 房间详情 - 房间资产 - 详情
export const houseAssetColumns = [
    {
        label: "名称",
        prop: "codeName",
        render: (h, {row}) => {
            let {codeName, asstesName} = row;
            return codeName ? codeName : asstesName;
        }
    },
    {
        label: "类别",
        prop: "",
        render: (h, {row}) => {
            let {assetType} = row;
            return assetType;
        }
    },
    {
        label: "采购价",
        prop: "price",
        render: (h, {row}) => {
            return numberFormat(row.price ? (row.price / 100) : 0);
        }
    },
    {
        label: "供货商",
        prop: "companyName"
    },
    {
        label: "状态",
        prop: "state",
        render: (h, {row}) => {
            return row.state !== 1 ? row.state : "";
        }
    },
    {
        label: "负责人",
        prop: "leasor"
    },
    {
        label: "售后电话",
        prop: "companyPhone"
    }
];
// 2022/06/08 王江毅 房间详情 - 房间资产 - 详情
export const assetColumns = [
    {
        type: 'selection',
        width: 70
    },
    {
        label: "序号",
        type: "index",
        width: 60
    },
    {
        label: "资产编号",
        prop: "assetsSn",
    },
    {
        label: "资产类别",
        prop: "assets.assetType",
    },
    {
        label: "资产名称",
        prop: "assetsName",
    },
    {
        label: "规格型号",
        prop: "assets.model"
    },
    {
        label: "价格/单位",
        render: (h, {row}) => {
            const {assets: {price, unit}} = row;
            return numberFormat(ParseFloatFormat(price)) + '/' + unit;
        }
    },
    {
        label: "购入时间",
        prop: "assets.intakeDate",
        render: (h, {row}) => {
            const {assets: {intakeDate}} = row;
            return intakeDate ? timeFormat(new Date(intakeDate)) : '';
        }
    },
    // 2024/01/11 张晓瑜修改核验状态为根据true、false进行展示
    {
        label: "核验状态",
        prop: "verify",
        render: (h, { row }) => {
          const verify = row.verify;
          let verifyName = verify ? "已核验" : "未核验";
          return h('span', verifyName);
        }
    }
];

// 合同详情 - 同住人 - 表格
export const lesseeTongZuRenColumns = [
    {
        prop: "name",
        label: "姓名"
    },
    {
        prop: "tel",
        label: "手机号"
    },
    {
        prop: "idcard",
        label: "证件号"
    },
    {
        prop: "relationship",
        label: "与承租人关系"
    }
];

// 房源管理列表 tableColumn
export function tableColumn(_this) {
    return [
        {
            type: 'selection',
            width: 70,
            selectable:(row) => {
                // console.log(row)
                return !row.tuiFang;
            },
        },
        {
            type: 'index',
            label: '序号',
            width: 60
        },
        {
            prop: 'address',
            label: '房间地址',
            width: 200
        },
        {
            // 陈守亮-2023-10-9-17-00-表格间距样式优化
            prop: 'paymentDisabledName',
            label: '状态',
            width: 100
        },
        {
            prop: 'communityName',
            label: '所在小区',
            width: 120
        },
        {
            // 陈守亮-2023-10-9-17-00-表格间距样式优化
            prop: 'communityDistrict',
            label: '所在区域',
            width: 100
        },
        {
            prop: 'outsideArea',
            label: '建筑面积(㎡)',
            width: 100
        },
        {
            prop: 'insideArea',
            label: '室内面积(㎡)',
            width: 100
        },
        {
            prop: 'monthPriceAmount',
            label: '单价',
            width: 100,
            render: (h, {row}) => {
                return numberFormat(row.monthPriceAmount ? (row.monthPriceAmount / 100) : 0);
            }
        },
        {
            prop: 'apartmentType',
            label: '户型',
            width: 130
        },
        {
            prop: 'comment',
            label: '房型',
            width: 100
        },
        {
            prop: 'propertyCode',
            label: '房源类型',
            width: 100,
        },
        {
            prop: 'rentStatus',
            label: '出租状态',
            width: 100,
        },
        {
            prop: 'leaseModeCode',
            label: '出租类型',
            width: 100,
            render: (h, {row}) => {
                let {leaseModeCode} = row
                switch (leaseModeCode) {
                    case 0:
                        return '整租'
                }
            }
        },
        {
            prop: 'isLetOutRent',
            label: '是否上架',
            width: 100,
            render: (h, {row}) => {
                let {isLetOutRent} = row
                switch (isLetOutRent) {
                    case 0:
                        return '否'
                    case 1:
                        return '是'
                }
            }
        },
        {
            prop: 'assetNum',
            label: '资产数量',
            width: 100
        },
    ]
}


// 2023/08/22 张晓瑜房间详情 - 合同备案 - 表格
export function FilingColumns(that) {
    return [
        {
            label: "房间核验码",
            prop: "housingCode",
            width: 150,
        },
        {
            label: "合同编号",
            prop: "contractNo",
            width: 150,
        },
        {
            label: "房屋坐落",
            prop: "location",
            width: 150,
        },
        {
            label: "押金",
            prop: "deposit",
            width: 150,
        },
        {
            label: "支付类型",
            prop: "payType",
            width: 150,
            render: (h, {row}) => {
                const {payType} = row;
                //年1   季度2    月3    天4     半年5     其他6
                let type = "";
                switch (Number(payType)) {
                    case 1:
                        type = "年";
                        break;
                    case 2:
                        type = "季度";
                        break;
                    case 3:
                        type = "月";
                        break;
                    case 4:
                        type = "天";
                        break;
                    case 5:
                        type = "半年";
                        break;
                    case 6:
                        type = "其他";
                        break;
                }
                return h("span", (type));
            }
        },
        {
            label: "支付方式",
            prop: "qtzffs",
            width: 150,
        },
        {
            label: "出租方式",
            prop: "rentType",
            width: 150,
        },
        {
            label: "出租房间名称",
            prop: "roomName",
            width: 150,
        },
        {
            label: "出租房间编号",
            prop: "roomNum",
            width: 150,
        },
        {
            label: "租赁面积",
            prop: "rentArea",
            width: 150,
        },
        {
            label: "租金",
            prop: "monthlyRent",
            width: 150,
        },
        {
            label: "出租开始时间",
            prop: "rentStartTime",
            width: 150,
        },
        {
            label: "出租终止时间",
            prop: "rentEndTime",
            width: 150,
        },
        {
            label: "经纪人姓名",
            prop: "brokerName",
            width: 150,
        },
        {
            label: "经纪人身份证号",
            prop: "brokerID",
            width: 150,
        },
        {
            label: "中介机构名称",
            prop: "intermediaryName",
            width: 150,
        },
        {
            label: "修改业务编号",
            prop: "businessNo",
            width: 150,
        },
        {
            label: "网签备注说明",
            prop: "remark",
            width: 150,
        },
        {
            label: "备案信息",
            prop: "statusMsg",
            width: 150,
        },
    ];
}
